module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/theme/favicon.png"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"partnershipId":"226f8b9f-3248-4758-ab53-b247175993af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
