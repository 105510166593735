// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-oauth-2-callback-js": () => import("../src/pages/app/oauth2/callback.js" /* webpackChunkName: "component---src-pages-app-oauth-2-callback-js" */),
  "component---src-pages-caregivers-index-js": () => import("../src/pages/caregivers/index.js" /* webpackChunkName: "component---src-pages-caregivers-index-js" */),
  "component---src-pages-community-js": () => import("../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-educators-index-js": () => import("../src/pages/educators/index.js" /* webpackChunkName: "component---src-pages-educators-index-js" */),
  "component---src-pages-educators-interactive-js": () => import("../src/pages/educators/interactive.js" /* webpackChunkName: "component---src-pages-educators-interactive-js" */),
  "component---src-pages-educators-spanish-js": () => import("../src/pages/educators/spanish.js" /* webpackChunkName: "component---src-pages-educators-spanish-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pharmacists-index-js": () => import("../src/pages/pharmacists/index.js" /* webpackChunkName: "component---src-pages-pharmacists-index-js" */),
  "component---src-pages-pharmacists-spanish-js": () => import("../src/pages/pharmacists/spanish.js" /* webpackChunkName: "component---src-pages-pharmacists-spanish-js" */),
  "component---src-pages-students-js": () => import("../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */)
}

